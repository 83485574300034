import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Player from '@vimeo/player'

import Image from 'components/Image'
import { Wrapper } from 'components/common'
import { BORDER } from 'styles/tokens'

import playButton from 'images/play-button.svg'

const StyledImageWrapper = styled(Wrapper)`
  position: relative;
  cursor: pointer;
  z-index: 2;
  opacity: 1;
  transition: opacity 1s;

  ${({ $hide }) => $hide && `display: none;`}

  ${({ $fade }) => $fade && `opacity: 0;`}
`

const StyledPlayButton = styled.img`
  position: absolute;
  top: 50%;
  left 50%;
  transform: translate(-50%, -50%);
  width: 60px;
`

const StyledEmbedContainer = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  border-radius: ${BORDER.RADIUS.S};

  iframe {
    z-index: 1;
  }

  iframe,
  .embed-container object,
  .embed-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

const VimeoVideo = ({ autoPlay, thumbnail, title, src }) => {
  const [isPlayMode, setIsPlayMode] = useState(false)
  const [isPlaying, setIsPlaying] = useState(false)
  const iframeEl = useRef(null)

  const hideImage = () => {
    setIsPlaying(true)
    setTimeout(() => setIsPlayMode(true), 1000)
  }

  useEffect(() => {
    if (autoPlay) {
      const player = new Player(iframeEl.current)
      player.on('play', hideImage)

      // Fallback for mobile
      setTimeout(hideImage, 3000)
    }
  }, [])

  return (
    <StyledEmbedContainer>
      <StyledImageWrapper
        $fade={isPlaying}
        $hide={isPlayMode}
        onClick={() => !autoPlay && setIsPlayMode(true)}
      >
        <Image
          sizes={{ ...thumbnail.childImageSharp.fluid, aspectRatio: 16 / 9 }}
        />
        {!autoPlay && <StyledPlayButton src={playButton} alt="Play video" />}
      </StyledImageWrapper>
      {(autoPlay || isPlayMode) && (
        <iframe
          src={src}
          frameBorder="0"
          allowFullScreen
          allow="autoplay; fullscreen"
          title={title}
          ref={iframeEl}
        />
      )}
    </StyledEmbedContainer>
  )
}

VimeoVideo.propTypes = {
  autoPlay: PropTypes.bool,
  thumbnail: PropTypes.shape({
    childImageSharp: PropTypes.shape({
      fluid: PropTypes.object,
    }),
  }),
  title: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
}

VimeoVideo.defaultProps = {
  autoPlay: false,
  thumbnail: null,
}

export default VimeoVideo
